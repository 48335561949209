import "./zeplin-globals";
import "./analytics";
import Cookies from "../foundation/cookies";
import { FORCED_LIGHT_THEME_CLASS } from "../foundation/theme";

function loadStripeScript() {
    // already loaded
    if (document.head.querySelector("#STRIPE_CHECKOUT")) {
        return;
    }

    const stripeScript = document.createElement("script");

    stripeScript.src = "https://checkout.stripe.com/checkout.js";
    stripeScript.id = "STRIPE_CHECKOUT";
    stripeScript.defer = true;

    document.head.appendChild(stripeScript);
}

function init() {
    document.body.classList.add(FORCED_LIGHT_THEME_CLASS);

    // We use dialog polyfill if the browser does not support the native dialog element, which requires a layout hack
    // The same applies to Chrome versions between 37 and 87, although those versions support <dialog>
    // Starting in Chrome 88, Safari 15.4 and Firefox 98, <dialog> is supported properly and we don't need to add this hack
    // (?:\/|\sv) this part is to cover both normal user agents and windows app user agents
    // We have a custom user agent in Windows app in it we wrote it as Chrome v88
    const hasNativeDialogSupport = typeof HTMLDialogElement === "function";
    const matchIfChromeBasedEngine = navigator.userAgent.match(/Chrome(?:\/|\sv)(\d+)/i);
    const CHROME_VERSION_TO_CHECK = 88;

    if (!hasNativeDialogSupport) {
        document.body.classList.add("autoHeightDialogs");
    } else if (matchIfChromeBasedEngine) {
        const [, versionString] = matchIfChromeBasedEngine;
        const deviceChromeVersion = parseInt(versionString, 10);
        if (deviceChromeVersion < CHROME_VERSION_TO_CHECK) {
            document.body.classList.add("autoHeightDialogs");
        }
    }

    window.addEventListener("online", () => {
        if (!Cookies.get("userToken")) {
            Zeplin.requireLogin(false);
        }
    });
}

window.addEventListener("DOMContentLoaded", async () => {
    const expirePromise = import ("./expire");
    const markerPromise = import ("./marker");

    await Promise.all([
        expirePromise,
        markerPromise
    ]);

    loadStripeScript();

    window.addEventListener("storage", ev => {
        if (ev.storageArea === localStorage && ev.key === "user") {
            const oldUserObj = JSON.parse(ev.oldValue);
            const newUserObj = JSON.parse(ev.newValue);

            if (!newUserObj) {
                Zeplin.requireLogin(false);
                return;
            }

            if (!oldUserObj && newUserObj) {
                Zeplin.redirectBack();
            }
        }
    });
});

init();
